import React,{useState, useEffect, useRef} from 'react'; 
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import '../OnboardForm/OnboardForm.css'
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { VBCUserValidationUrl } from "../../Utils/Urls";

export default function UserOnboardForm() {
    const [AccessCode, setAccessCode] = useState('');
    const [ghlUserId, setGhlUserId] = useState('');
    const toast = useRef(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get('code');
    
        if (codeParam) {
            setAccessCode(codeParam);
        }
      }, []);

      const handleGetAccessCode = () => {
        window.location.href = 'https://api.vonage.com/authorize?scope=openid&response_type=code&redirect_uri=https://frontendvonage.allsiterentals.com/user_onboarding&client_id=X5KjvT4T8EUYrETLWJ5fB8zyVaga';
      };

      const handleSubmit = (e)=>{
        e.preventDefault();
        const data = {
            ghlUserId,
            AccessCode
        }
        axios.post(VBCUserValidationUrl, data, {
            headers: {'Content-Type': 'application/json' },
        })
        .then((response) => {
            if (response.status === 200) {
                toast.current.show({severity:'success', summary: 'Success', detail:response.data, life: 2000});
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
            }
            })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
        })
      }

    return (
        <div className="card flex flex-column md:flex-row gap-3 onboard-card">
            <Toast ref={toast} />
            <form onSubmit={handleSubmit}>
            <div className="p-inputgroup flex-1 onboard-lable">
                <label className="font-bold block mb-2">
                Authorization Code
                </label>
            </div>
            <div className="p-inputgroup flex-1 onboard-text">
                <InputText placeholder="Enter your Authorization Code" value={AccessCode} onChange={(e)=>setAccessCode(e.target.value)} required/>
                <Button icon="pi pi-search" className="p-button-success" onClick={handleGetAccessCode}/>
            </div>

            <div className="p-inputgroup flex-1 onboard-lable">
                <label className="font-bold block mb-2">
                GHL User ID
                </label>
            </div>
            <div className="p-inputgroup flex-1 onboard-text">
                <InputText placeholder="Enter your GHL User ID" value={ghlUserId} onChange={(e)=>setGhlUserId(e.target.value)} required/>
            </div>
            <Button type='submit' label="Validate Credentials & Submit"/>
            </form>
        </div>
    )
}