import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import axios from '../../Utils/axios'
import { get_all_extensions, add_extension, delete_extension } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';


export default function ExtensionTable() {
    const {locationId} = useParams()
    const [extensions, setExtensions] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fullName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lead_phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        user_phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        duration: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        user_extension: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        call_result: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [loading, setLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false)
    const [extensionVisible, setExtensionVisible] = useState(false);
    const toast = useRef(null);
    
   
    const [newExtension, setNewExtension] = useState(null);
    const [newUserId, setNewUserId] = useState(null);
    const [newPhone, setNewPhone] = useState(null);

    useEffect(()=>{

        axios.post(`${get_all_extensions}${locationId}`, {
            headers: {'Content-Type': 'application/json'},
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data.all_phones)
                    setExtensions(response.data.all_phones)
                    setLoading(false);
                }
            })
    },[get_all_extensions, isMounted])

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    
    const renderExtensionHeader = () => {

        return (
            // <div className="flex justify-content-between table-header-container" style={{display:'flex', justifyContent:'end'}}>
            //      <div className='sync-button-container' style={{width: 'min-content'}}>
            //         <Button className='sync-button' label="ADD" icon="pi pi-plus" onClick={()=>setExtensionVisible(true)} />
            //     </div>
            // </div>

            <div className="flex justify-content-end header-container">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
            <span className="p-float-label filter-card-container">
                <Button className='sync-button' label="ADD" icon="pi pi-plus" onClick={()=>setExtensionVisible(true)} />
            </span>
            </div>
        );
    };

    const handleExtensionOnHide = () =>{
        // setNewExtension(null)
        // setNewUserId(null)
        setNewPhone(null)
        setExtensionVisible(false)
    }

    function formatPhoneNumber(number) {
        // Convert the number to a string
        let numberStr = number.toString();
    
        // Check if the number has exactly 10 digits
        if (numberStr.length === 10) {
            // Concatenate '1' to the front of the string
            numberStr = '1' + numberStr;
        }
    
        return numberStr;
    }

    const handleSubmitNewExtension = () =>{
        if (newPhone){
            const formattedNumber = formatPhoneNumber(newPhone);
            if(formattedNumber.length === 11){
                const body = {
                    // "extension" : newExtension,
                    // 'user_id' : newUserId,
                    "vonage_phone": formattedNumber
                }
    
                const addExtensionURL = `${add_extension}${locationId}`
    
                axios
                    .post(addExtensionURL, body, {
                    headers: { "Content-Type": "application/json" },
                    })
                    .then((response) => {
                    if (response.status === 201){
                        setIsMounted(!isMounted)
                        handleExtensionOnHide()
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Phone Added', life: 3000 });
                        
                    }
                    else if(response.status === 207){
                        toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 3000});
                    }
                    else{
                        toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                    }
                    })
                    .catch((err) => {
                        toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                    });
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Please enter a valid phone', life: 3000});
            }  
        }
        else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Please enter a phone', life: 3000});
        }
    }

    const handleDeleteExtension = (phone) =>{
            
        const deleteExtensionURL = `${delete_extension}${phone}`

        axios
            .post(deleteExtensionURL, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                setIsMounted(!isMounted)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Phone Deleted', life: 3000 });
                
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
        
    }

    const extensionAddFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleSubmitNewExtension} autoFocus />
        </div>
    );

    const reject = () => {
        console.log('closed')
    }

    const handleExtensionDelete = (phone) => {
        
        confirmDialog({
            message: 'Are you sure you want to delete this phone?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteExtension(phone),
            reject
        });

    }

    const deleteBody = (rowData) => {
        const phone = rowData.vonage_number
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleExtensionDelete(phone)}/>;
    };

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };
    
    const extensionHeader = renderExtensionHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <DataTable value={extensions} showGridlines rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={extensionHeader} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                    dataKey="vonage_number"
                    stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No Phone found." tableStyle={{ minWidth: '100%' }}>
                <Column header="SR NO" className="padding-0" style={{ width: '4rem'  }} body={srNoBodyTemplate} />
                {/* <Column field="extension" header="Extension" sortable style={{ width: '45%' }}></Column> */}
                {/* <Column field="ghl_user_id" header="User ID" sortable style={{ width: '45%' }}></Column> */}
                <Column field="vonage_number" header="Phone" sortable style={{ minWidth: '1rem' }}></Column>
                <Column body={deleteBody} style={{ width: '4rem'}}></Column>  
            </DataTable>

            
            <Dialog header="Add New Extension" visible={extensionVisible} onHide={handleExtensionOnHide} footer={extensionAddFooter}>
            <div>
                <div className='purchase-form-group'>
                <div className="flex justify-content-center">
                    <InputNumber value={newPhone} onValueChange={(e) => setNewPhone(e.value)} maxLength={10} useGrouping={false} placeholder="Phone (without country code)" />
                </div>
                {/* <div className="flex justify-content-center">
                    <InputText type="text" className="p-inputtext language-multiselect" placeholder="User ID" value={newUserId} onChange={(e) => setNewUserId(e.target.value)}/>
                </div> */}
                </div>
            </div>
            </Dialog>
        </div>
    );
}