import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './calltable.css'
import React, { useState, useEffect, useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import ReactAudioPlayer from 'react-audio-player';
import axios from '../../Utils/axios'
import { get_all_calls, calls_fetch, add_extension, delete_extension, get_VBC_access_token, update_outcome, contact_update } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';


export default function CallTable() {
    const {locationId} = useParams()
    const {userId} = useParams()
    const [customers, setCustomers] = useState(null);
    const [extensions, setExtensions] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        fullName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lead_phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        user_phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        duration: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        user_extension: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        call_result: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [trackIsMounted, setTrackIsMounted] = useState(false)
    const [sync, setSync] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [recordingVisible, setRecordingVisible] = useState(false);
    const [outcomeVisible, setOutcomeVisible] = useState(false);
    const [extensionVisible, setExtensionVisible] = useState(false);
    const toast = useRef(null);
    const [editChips, setEditChips] = useState([]);
    const [globalExtensions, setGlobalExtensions] = useState([]);
    const [newEditChipText, setNewEditChipText] = useState('');
    const [editContactId, setEditContactId] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [editVisible, setEditVisible] = useState(false);
    const generateDateRange = () => {
        const today = new Date();
        const lastMonth = new Date();
        lastMonth.setMonth(today.getMonth() - 1);
    
        return [lastMonth, today];
      };
    const [dates, setDates] = useState(null);
    const [audioSrc, setAudioSrc] = useState(null);
    const [selectedOutcome, setSelectedOutcome] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [selectedCallId, setSelectedCallId] = useState(null);
    const [selectedCallExtension, setSelectedCallExtension] = useState(null);
    const iconsAndText = [
        { icon: 'pi pi-calendar-plus', text: 'New Booking' },
        { icon: 'pi pi-phone', text: 'Spam Call' },
        { icon: 'pi pi-spinner', text: 'Order Status' },
        { icon: 'pi pi-dollar', text: 'Price is too high' },
        { icon: 'pi pi-hourglass', text: 'Need time to think or discuss' },
        { icon: 'pi pi-times-circle', text: 'Did not Book' },
        { icon: 'pi pi-question', text: 'Out of Service Area' }
    ];
    const [newExtension, setNewExtension] = useState(null);
    const [newUserId, setNewUserId] = useState(null);
    

    // useEffect(() => {
    //     CustomerService.getCustomersMedium().then((data) => {
    //         setCustomers(getCustomers(data));
    //         setLoading(false);
    //     });
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (dates === null || dates[1] !== null){
            setLoading(true);
            const data = {
                dates
            }
            axios.post(`${get_all_calls}${locationId}/${userId}`, data, {
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setCustomers(response.data.calls);
                        setExtensions(response.data.all_extensions)
                        setEditChips(response.data.extensions)
                        setGlobalExtensions(response.data.global_extensions)
                        setLoading(false);
                    }
                })
        }
    },[get_all_calls, isMounted, dates])


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end header-container">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
                <span className="p-float-label filter-card-container">
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" className="full-width"/>
                    <label htmlFor="ms-cities">Select Date Range</label>
                </span>
            </div>
        );
    };

    const renderExtensionHeader = () => {

        return (
            <div className="flex justify-content-between table-header-container" style={{display:'flex', justifyContent:'end'}}>
                 <div className='sync-button-container' style={{width: 'min-content'}}>
                    <Button className='sync-button' label="ADD" icon="pi pi-plus" onClick={()=>setExtensionVisible(true)} />
                </div>
            </div>
        );
    };

    const handleExtensionOnHide = () =>{
        setNewExtension(null)
        setNewUserId(null)
        setExtensionVisible(false)
    }

    const handleSubmitNewExtension = () =>{
        if (newExtension && newUserId && newUserId.trim()){
            const body = {
                "extension" : newExtension,
                'user_id' : newUserId
            }

            const addExtensionURL = `${add_extension}${locationId}`

            axios
                .post(addExtensionURL, body, {
                headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                if (response.status === 201){
                    setIsMounted(!isMounted)
                    handleExtensionOnHide()
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Extension Added', life: 3000 });
                    
                }
                else if(response.status === 207){
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 3000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                }
                })
                .catch((err) => {
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                });
        }
        else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Please fill both fields', life: 3000});
        }
    }

    const handleDeleteExtension = (extension) =>{
            
        const deleteExtensionURL = `${delete_extension}${extension}`

        axios
            .post(deleteExtensionURL, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                setIsMounted(!isMounted)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Extension Deleted', life: 3000 });
                
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
        
    }

    const extensionAddFooter = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={handleSubmitNewExtension} autoFocus />
        </div>
    );

    const reject = () => {
        console.log('closed')
    }

    const handleExtensionDelete = (extension) => {
        
        confirmDialog({
            message: 'Are you sure you want to delete this extension?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteExtension(extension),
            reject
        });

    }

    const deleteBody = (rowData) => {
        const extension = rowData.extension
        return <Button icon="pi pi-trash" severity="danger" aria-label="Delete" onClick={()=>handleExtensionDelete(extension)}/>;
    };

    const syncHandle = () =>{
        setIsMounted(!isMounted)
        if(sync == false){
            axios.post(calls_fetch,{
                headers: {'Content-Type': 'application/json'},
            })
            .then((response)=>{
                if (response.status === 200){
                    setTrackIsMounted(!trackIsMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Sync has started', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                }
            })
        }
        else{
            toast.current.show({severity:'error', summary: 'Error', detail:'It is already syncing', life: 2000});
        }
    }

    const hideSettingsDialog = () => {
        setSettingsVisible(false);
        setNewEditChipText('')
    };

    const hideRecordingDialog = () => {
        setRecordingVisible(false)
    }

    const saveSettings = () => {


        const body = {
            "extensions" : editChips
        }

        const addExtensionURL = `${add_extension}${locationId}`

        axios
            .post(addExtensionURL, body, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                hideSettingsDialog()
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Extension Updated', life: 3000 });
                setCustomers(response.data.calls);
                setEditChips(response.data.extensions)
                setGlobalExtensions(response.data.global_extensions)
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });
  
    };

    const handleAddEditChip = () => {
        if (newEditChipText){

            const trimmedText = newEditChipText.toString();
    
            if (trimmedText !== '' && !globalExtensions.includes(trimmedText)) {
                setEditChips([...editChips, trimmedText]);
                setGlobalExtensions([...globalExtensions, trimmedText])
                setNewEditChipText('');
            }
            else if (globalExtensions.includes(trimmedText)){
                toast.current.show({severity:'error', summary: 'Error', detail:'Extension should be unique', life: 3000});
            }

        }
    };

    const handleEditChipKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAddEditChip()
        }
    };

    const handleRemoveEditChip = (value) => {
        setEditChips((prevChips) => {
            const newChips = prevChips.filter((chip) => chip !== value);
            return newChips;
        });
    };

    const settingsDialogFooter = (
        <div className="dialog-footer">
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideSettingsDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveSettings} />
        </div>
    );

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };

      const fetchAudio = (url, token) => {
        const headers = {
          'Accept': 'audio/mpeg',
          'Authorization': `Bearer ${token}`
        };
    
        fetch(url, {
          method: 'GET',
          headers: headers
        })
        .then(response => {
          if (!response.ok) {
            setLoading(false)
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const audioURL = URL.createObjectURL(blob);
          setAudioSrc(audioURL);
          setLoading(false)
          setRecordingVisible(true)
        })
        .catch(error => {
            setLoading(false)
            console.error('There was a problem with your fetch operation:', error);
        });
      };

    const handleRecordDialog = (url) => {
        if (url){
            setLoading(true)
            axios
                .get(get_VBC_access_token, {
                headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    if (response.status === 200){
                        const token = response.data
                        console.log(token)
                        fetchAudio(url, token)
                    }
                    else{
                        toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                    }
                })
                .catch((err) => {
                    toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
                });
        }
        else{
            toast.current.show({severity:'error', summary: 'No data', detail:'No recording found', life: 3000});
        }

    }

    const handleOutcomeDialog = (call_id, outcome, notes, extension) => {
        setSelectedOutcome(outcome)
        setSelectedCallId(call_id)
        setSelectedCallExtension(extension)
        setSelectedNote(notes)
        setOutcomeVisible(true)

    }

    const handleCloseOutcome = () => {
        setSelectedOutcome(null)
        setSelectedCallId(null)
        setSelectedCallExtension(null)
        setSelectedNote(null)
        setOutcomeVisible(false)
    }

    const handleOutcomeSubmit = () => {
        if (selectedOutcome){
            setLoading(true)
            const body = {
                'call_id': selectedCallId,
                'extension': selectedCallExtension,
                'outcome': selectedOutcome,
                'note': selectedNote
            }

            axios.post(update_outcome, body, {
            headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
            if (response.status === 200){
                setLoading(false)
                setSelectedOutcome(null)
                setSelectedCallId(null)
                setSelectedCallExtension(null)
                setSelectedNote(null)
                setOutcomeVisible(false)
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Outcome Updated', life: 3000 });
                setIsMounted(!isMounted)
            }
            else if(response.status === 304){
                setLoading(false)
                toast.current.show({severity:'error', summary: 'Error', detail:'Call is not synced to GHL! Please sync and try again', life: 3000});
            }
            else{
                setLoading(false)
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            }
            })
            .catch((err) => {
                setLoading(false)
                toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
            });

        }
        else{
            toast.current.show({severity:'error', summary: 'Error', detail:'Please select an outcome', life: 3000});
        }
    }

    const recordingBody = (rowData) => {

        return(
            <div className='status'>
                <Button className="set-recording-button" icon="pi pi-caret-right" onClick={() => handleRecordDialog(rowData.recording_url)}/>
            </div>
        )
    }

    const outcomeBody = (rowData) => {

        return(
            <div className='status'>
                <Button className="set-outcome-button" icon="pi pi-ellipsis-h" onClick={() => handleOutcomeDialog(rowData.call_id, rowData.call_outcome, rowData.notes, rowData.user_extension)}/>
            </div>
        )
    }

    const handleGetContactDetails = (rowData) => {
        setEditContactId(rowData.contact_id)
        setFirstName(rowData.first_name)
        setLastName(rowData.last_name)
        setEmail(rowData.email)
        setEditVisible(true)      
        
    }

    const handleEditOnHide = () => {
        setEditContactId(null)
        setFirstName(null)
        setLastName(null)
        setEmail(null)
        setEditVisible(false)
    }

    const editTemplate = (rowData) => {

        return(
            <div className='status'>
                <Button className="edit-button" icon="pi pi-pencil" onClick={()=>handleGetContactDetails(rowData)}/>
            </div>
        )
    };

    const onRowEditComplete = (e) => {

        const data = {
            'contact_id': editContactId,
            'first_name' : firstName,
            'last_name' : lastName,
            'email' : email
        }

    
        axios.post(contact_update, data,{
            headers: {'Content-Type': 'application/json'},
        })
        .then((response)=>{
            if (response.status === 200){
                setEditContactId(null)
                setFirstName(null)
                setLastName(null)
                setEmail(null)
                setEditVisible(false)
                setIsMounted(!isMounted);
                toast.current.show({severity:'success', summary: 'Success', detail:'Contact Details updated', life: 3000});
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong', life: 3000});
        }) 
        
    };

    const footerEditContent = (
        <div>
            <Button className='view-button' label="Submit" icon="pi pi-check" onClick={onRowEditComplete} autoFocus />
        </div>
    );

    const header = renderHeader();

    const extensionHeader = renderExtensionHeader();

    return (
        <div className="card">
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="settings-container">
                <Button className="p-button-primary" label={sync ? "Synchronizing": "Sync"} icon={sync? "pi icon-size pi-spin pi-sync": "pi icon-size pi-sync"} onClick={syncHandle} />
                {/* <Button className="p-button-danger" label="Extensions" icon="pi icon-size pi-cog" onClick={() => setSettingsVisible(true)} /> */}
            </div>
            <DataTable value={customers} scrollable scrollHeight="70vh" showGridlines paginator rows={50} rowsPerPageOptions={[50, 100]} dataKey="call_id" filters={filters} filterDisplay="row" loading={loading}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    globalFilterFields={['fullName', 'lead_phone', 'user_phone', 'user_extension', 'duration', 'call_result', 'email']} header={header} emptyMessage="No calls.">
                <Column header="SR NO" className="padding-0" style={{ width: '4rem'  }} body={srNoBodyTemplate} />
                <Column field="fullName" header="Name" sortable style={{ minWidth: '12rem' }} />
                <Column field="email" header="Email" sortable style={{ minWidth: '12rem' }} />
                <Column field="lead_phone" header="Phone" sortable style={{ minWidth: '12rem' }} />
                <Column field="user_phone" header="User Phone" sortable style={{ minWidth: '12rem' }} />
                <Column field="user_extension" header="Extension" sortable style={{ width: '4rem' }} />
                <Column field="start" header="Time" sortable style={{ minWidth: '12rem' }} />
                <Column field="duration" header="Duration" sortable style={{ width: '4rem' }} />
                <Column field="call_result" header="Call Status" sortable style={{ minWidth: '10rem' }} />
                <Column field="call_outcome" header="Call Outcome" sortable style={{ minWidth: '12rem' }} />
                <Column field="recording_url" header="Call Recording" body={recordingBody} style={{ width: '4rem' }} />
                <Column header="Set Outcome" body={outcomeBody} style={{ width: '4rem' }} />
                <Column header="Edit Contact" body={editTemplate} style={{ width: '2rem'  }}></Column>
            </DataTable>

            {/* <Dialog visible={settingsVisible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Add Extension" modal className="p-fluid" footer={settingsDialogFooter} onHide={hideSettingsDialog}>
                <div className="field">
                    <label htmlFor="checkpoints" className="font-bold">
                        Extensions
                    </label>
                    <div className='chip-container' style={{marginTop:'1rem'}}>
                    {editChips.map((label, index) => (
                        <Chip key={index} label={label} icon='pi pi-times-circle' onClick={() => handleRemoveEditChip(label)} />
                    ))}
                    </div>
                    <div className="flex gap-2 p-inputgroup flex-1" style={{marginTop:'1rem'}}>
                        
                        <InputNumber id="checkpoint" value={newEditChipText} onValueChange={(e) => setNewEditChipText(e.target.value)} />
                        <Button icon="pi pi-check" className="p-button-success" onClick={handleAddEditChip}/>
                    </div>
                </div>
            </Dialog> */}

            <Dialog visible={settingsVisible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Extensions" modal className="p-fluid" onHide={hideSettingsDialog}>
                <DataTable value={extensions}  rows={10} rowsPerPageOptions={[5, 10, 50, 100]} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" scrollable scrollHeight="65vh" header={extensionHeader} filters={filters} loading={loading} onFilter={(e) => setFilters(e.filters)}
                        dataKey="extension"
                        stateStorage="session" stateKey="dt-state-demo-local" emptyMessage="No Extensions found." tableStyle={{ minWidth: '100%' }}>
                    <Column field="extension" header="Extension" sortable style={{ minWidth: '1rem' }}></Column>
                    <Column field="ghl_user_id" header="User ID" sortable style={{ minWidth: '1rem' }}></Column>
                    <Column body={deleteBody} style={{ minWidth: '1rem'}}></Column>  
                </DataTable>
            </Dialog>

            <Dialog header="Add New Extension" visible={extensionVisible} onHide={handleExtensionOnHide} footer={extensionAddFooter}>
            <div>
                <div className='purchase-form-group'>
                <div className="flex justify-content-center">
                    <InputNumber value={newExtension} onValueChange={(e) => setNewExtension(e.value)} useGrouping={false} placeholder="Extension" />
                </div>
                <div className="flex justify-content-center">
                    <InputText type="text" className="p-inputtext language-multiselect" placeholder="User ID" value={newUserId} onChange={(e) => setNewUserId(e.target.value)}/>
                </div>
                </div>
            </div>
        </Dialog>

            <Dialog visible={recordingVisible} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Call Recording" modal className="p-fluid" onHide={hideRecordingDialog}>
                <div className="field">
                    
                    <ReactAudioPlayer src={audioSrc} autoPlay controls style={{width: '100%'}}/>
                    
                </div>
            </Dialog>

            <Dialog header="HOW DID THE CALL GO?" className="outcome-dialog" visible={outcomeVisible} onHide={() => handleCloseOutcome()}>
                <div className="modal-content">
                {iconsAndText.map((item, index) => (
                    <div className="rectangle-box" key={item.text} onClick={() => setSelectedOutcome(item.text)}>
                    <div className="box-logo">
                        <span className={`pi ${item.icon} outcome-icon`}></span>
                    </div>
                    <div className={`box-content ${selectedOutcome === item.text ? 'box-content-selected' : ''}`}>
                        <p>{item.text}</p>
                    </div>
                    </div>
                ))}
                <div className="callNotes-container">
                    <h3 className="callNotes-heading">Call notes</h3>
                    <InputTextarea placeholder="Any notes about this call?" value={selectedNote} onChange={(e) => setSelectedNote(e.target.value)} rows={5} cols={30} style={{width:'100%'}}/>
                </div>
                <div className="submit-buttons-container">
                <Button className="cancel-button" label="Cancel" onClick={() => handleCloseOutcome()} />
                <Button className="record-button" label="Update Outcome" icon="pi pi-phone" onClick={handleOutcomeSubmit}/>
                </div>
                </div>
            </Dialog>

            <Dialog header="Edit Contact" visible={editVisible} onHide={handleEditOnHide} footer={footerEditContent}>
            <div>
               
                <div className='edit-form-group'>

                    <div className="flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                        <InputText className='purchase-dropdown' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <label htmlFor="leadAmount">First Name</label>
                        </span>
                    </div>

                    <div className="flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                        <InputText className='purchase-dropdown' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            <label htmlFor="leadAmount">Last Name</label>
                        </span>
                    </div>

                    <div className="flex justify-content-center form-field-container mt-1">
                        <span className="p-float-label purchase-dropdown">
                        <InputText className='purchase-dropdown' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="leadAmount">Email</label>
                        </span>
                    </div>

                </div>

            </div>
            </Dialog>
        </div>
    );
}