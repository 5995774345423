// export const baseUrl = "http://127.0.0.1:8000";
export const baseUrl = "https://apvonage.allsiterentals.com";
export const ValidationUrl = "/validation";
export const VBCUserValidationUrl = "/vbc_user_validation"
export const get_all_calls = "/get_all_calls/"
export const get_all_extensions = "/get_all_extensions/"
export const calls_fetch = "/calls_fetch"
export const add_extension = "/add_extension/"
export const delete_extension = "/delete_extension/"
export const get_VBC_access_token ="/get_VBC_access_token"
export const update_outcome = "/update_outcome"
export const contact_update = "/update_contact"