import './App.css';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';
import OnboardForm from './Components/OnboardForm/OnboardForm';
import CallTable from './Components/CallTable/CallTable';
import UserOnboardForm from './Components/UserOnboardForm/UserOnboardForm';
import ExtensionTable from './Components/ExtensionTable/ExtensionTable';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<OnboardForm/>} />
          <Route path="/user_onboarding" element={<UserOnboardForm/>} />
          <Route path="/call/:locationId/:userId" element={<CallTable/>} />
          <Route path="/extension/:locationId" element={<ExtensionTable/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
